









import { Vue, Component, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import Company from "@/entity/Company";

@Component({
  components: {
    SectionTitle,
  },
})
export default class CompanyNoteCard extends Vue {
  @Prop({ required: true }) company!: Company;
}
