


















import { Vue, Component, Prop } from "vue-property-decorator";
import ContactList from "@/components/detail-page/ContactList.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import SectionHeading from "@/components/detail-page/SectionHeading.vue";
import OwnerName from "@/components/detail-page/OwnerName.vue";
import Address from "@/components/detail-page/Address.vue";
import Company from "@/entity/Company";

@Component({
  components: {
    ContactList,
    SectionHeading,
    SectionTitle,
    OwnerName,
    Address,
  },
})
export default class CompanyContactCard extends Vue {
  @Prop({ required: true }) company!: Company;
}
